a.active.tab-gray{
    background-color: #288638 !important;
}

.tab-gray {
    background-color: #97A29E !important;
}

.img-max-width {
    max-width: 25% !important;
}